export default class BusinessCategoryRuleDisplayMin {
  business_category_id: number;
  business_category_name: string;
  business_rule_id: number;
  business_rule_name: string;
  active?: boolean;
  version: number;
  regions: number[];
  status: string;
  enabledForRegion: boolean;
}
