import Right from '../rights/right';
import Role from './role';
import BusinessRule from '../business-rules/business-rule';
import Region from '../requests/region';
import Location from '../requests/location';
import ApplicationMinified from '../applications/application-min';

export default class User {
  id: number;
  qnumber: string;
  createdBy: string;
  modifiedBy: string;
  rights: Right[];
  roles: Role[];
  rules: BusinessRule[];
  standInUser: User;
  standInUsers: User[];
  myDelegates: User[];
  region: Region;
  locations: Location[];
  iNumber: string;
  employeeType: string;
  managerINumber: string;
  name: string;
  surname: string;
  email: string;
  approverDeputyId: number;
  departmentCode: string;
  approverCount: number;
  fullName: string;
  linkedApplicationsIDs: number[];
  linkedApplications: string;
  applications: ApplicationMinified[];
  requestsAccess: boolean;
  requestLinkedAccess: boolean;
}
