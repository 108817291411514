import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from '../pages/dashboard/dashboard.component';
import {BusinessCategoryComponent} from '../pages/master-admin/business-category/business-category.component';
import {BusinessRulesComponent} from '../pages/master-admin/business-rules/business-rules.component';
import {ApplicationsComponent} from '../pages/master-admin/applications/applications.component';
import {ApplicationRightsComponent} from '../pages/master-admin/application-rights/application-rights.component';
import {RegionsComponent} from '../pages/master-admin/regions/regions.component';
import {RegionsAdminsComponent} from '../pages/master-admin/regions-admins/regions-admins.component';
import {RegionsApplicationsComponent} from '../pages/region-admin/regions-applications/regions-applications.component';
// tslint:disable-next-line:max-line-length
import {
  SelectRegionApplicationsComponent
} from '../pages/region-admin/regions-applications/select-region-applications/select-region-applications.component';
import {RightsManagerComponent} from '../pages/region-admin/rights-manager/rights-manager.component';
import {SpecialRightsManagerComponent} from '../pages/region-admin/special-rights-manager/special-rights-manager.component';
// import {SpecialRightsApproverComponent} from '../pages/region-admin/special-rights-approver/special-rights-approver.component';
import {BusinessRuleRightComponent} from '../pages/master-admin/business-rule-right/business-rule-right.component';
// import {ComplianceManagerComponent} from '../pages/master-admin/compliance-manager/compliance-manager.component';
import {ComplianceApproverComponent} from '../pages/master-admin/compliance-approver/compliance-approver.component';
import {ViolationsComponent} from '../pages/compliance/violations/violations.component';
import {ApproverComponent} from '../pages/compliance/approver/approver.component';
import {ApplicationRightsCategoryComponent} from '../pages/master-admin/application-rights-category/application-rights-category.component';
import {LocationsComponent} from '../pages/region-admin/locations/locations.component';
import {AuthGuard} from '../shared/guards/auth.guard';
import {SingleRightsComponent} from '../pages/master-admin/single-rights/single-rights.component';
import {RegionBusinessRuleComponent} from '../pages/region-admin/region-business-rule/region-business-rule.component';
// import {RegionBusinessRuleRightsComponent} from '../region-admin/region-business-rule-rights/region-business-rule-rights.component';
import {LoginRedirectGuard} from '../shared/guards/redirect-guard.guard';
import {ApproverCurrentRequestComponent} from '../pages/approver/approver-current-request/approver-current-request.component';
import {ApproverDashboardComponent} from '../pages/approver/approver-dashboard/approver-dashboard.component';
import {ApproverCompletedRequestComponent} from '../pages/approver/approver-completed-request/approver-completed-request.component';
import {ApproverDeputyComponent} from '../pages/approver/approver-deputy/approver-deputy.component';
import {ComplianceCurrentRequestComponent} from '../pages/compliance/compliance-current-request/compliance-current-request.component';
import {ComplianceDashboardComponent} from '../pages/compliance/compliance-dashboard/compliance-dashboard.component';
import {RightsManagerCurrentComponent} from '../pages/rights-manager/rights-manager-current/rights-manager-current.component';
import {RightsManagerRequestDetailsComponent} from '../pages/rights-manager/rights-manager-request-details/rights-manager-request-details.component';
import {ApproverCompletedDashboardComponent} from '../pages/approver/approver-completed-dashboard/approver-completed-dashboard.component';
import {RightsManagerCompletedComponent} from '../pages/rights-manager/rights-manager-completed/rights-manager-completed.component';
import {RightsManagerCompletedDetailsComponent} from '../pages/rights-manager/rights-manager-completed-details/rights-manager-completed-details.component';
import {ArchiveDashboardComponent} from '../pages/archive/archive-dashboard/archive-dashboard.component';
import {ArchiveDetailsComponent} from '../pages/archive/archive-details/archive-details.component';
import {MyArchiveComponent} from '../pages/archive/my-archive/my-archive.component';
import {DepartmentsComponent} from '../pages/master-admin/departments/departments.component';
// import {
//   SingleRightsApplicationsComponent
// } from '../pages/master-admin/single-rights-applications/single-rights-applications.component';
import {UsersComponent} from '../pages/master-admin/users/users.component';
import {ApproverMyTeamComponent} from '../pages/approver/approver-my-team/approver-my-team.component';
import {TranslationsComponent} from '../pages/master-admin/translations/translations.component';
import {RegionBusinessCategoryComponent} from '../pages/region-admin/region-business-category/region-business-category.component';

const routes: Routes = [

  {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
  {
    path: 'login',
    canActivate: [LoginRedirectGuard],
    component: LoginRedirectGuard,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard/:qNumber',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'business-category',
    component: BusinessCategoryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'business-rules',
    component: BusinessRulesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'applications',
    component: ApplicationsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'application-rights-categories',
    component: ApplicationRightsCategoryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'application-rights',
    component: ApplicationRightsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'regions',
    component: RegionsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'regions-admins',
    component: RegionsAdminsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'regions-applications',
    component: RegionsApplicationsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'select-region-applications',
    component: SelectRegionApplicationsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rights-manager',
    component: RightsManagerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'special-rights-manager',
    component: SpecialRightsManagerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'department-codes',
    component: DepartmentsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'all-users',
    component: UsersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'translations',
    component: TranslationsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'business-rule-right',
    component: BusinessRuleRightComponent,
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'compliance-manager',
  //   component: ComplianceManagerComponent,
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'compliance-approver',
    component: ComplianceApproverComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'approver',
    component: ApproverComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'violations',
    component: ViolationsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'locations',
    component: LocationsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'single-rights',
    component: SingleRightsComponent,
    canActivate: [AuthGuard]
  }
  ,
  // {
  //   path: 'single-rights-applications',
  //   component: SingleRightsApplicationsComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'region-business-rule-rights',
  //   component: RegionBusinessRuleRightsComponent,
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'region-business-rule',
    component: RegionBusinessRuleComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'approver-current-request',
    component: ApproverCurrentRequestComponent,
    canActivate: [AuthGuard]
  }
  ,
  {
    path: 'approver-dashboard/:qNumber',
    component: ApproverDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'approver-dashboard',
    component: ApproverDashboardComponent,
    canActivate: [AuthGuard]
  }
  , {
    path: 'approver-completed-dashboard/:qNumber',
    component: ApproverCompletedDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'approver-completed-dashboard',
    component: ApproverCompletedDashboardComponent,
    canActivate: [AuthGuard]
  }
  ,
  {
    path: 'approver-completed-request',
    component: ApproverCompletedRequestComponent,
    canActivate: [AuthGuard]
  }
  ,
  {
    path: 'approver-deputy',
    component: ApproverDeputyComponent,
    canActivate: [AuthGuard]
  }
  ,
  {
    path: 'approver-my-team',
    component: ApproverMyTeamComponent,
    canActivate: [AuthGuard]
  }
  ,
  {
    path: 'compliance-current-request',
    component: ComplianceCurrentRequestComponent,
    canActivate: [AuthGuard]
  }
  ,
  {
    path: 'compliance-dashboard',
    component: ComplianceDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rights-manager-current',
    component: RightsManagerCurrentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rights-manager-completed',
    component: RightsManagerCompletedComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rights-manager-request-details/:qNumber',
    component: RightsManagerRequestDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rights-manager-request-details',
    component: RightsManagerRequestDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rights-manager-completed-details/:qNumber',
    component: RightsManagerCompletedDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rights-manager-completed-details',
    component: RightsManagerCompletedDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'archive-dashboard',
    component: ArchiveDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-archive',
    component: MyArchiveComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'archive-details/:regionId/:qnumber',
    component: ArchiveDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'region-business-category',
    component: RegionBusinessCategoryComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule { }
