import {Component, OnInit} from '@angular/core';
import {RegionHttpService} from 'src/app/service/http/region-http.service';
import Region from 'src/app/shared/model/requests/region';
import {SweetAlertService} from 'src/app/service/feedback/SweetAlertService.service';
import {UserHttpService} from 'src/app/service/http/user-http.service';
import {BusinessRuleHttpService} from 'src/app/service/http/business-rule-http.service';
import BusinessCategory from 'src/app/shared/model/business-rules/business-category';
import {BusinessCategoryHttpService} from 'src/app/service/http/business-category-http.service';
import RegionMinified from '../../../shared/model/requests/region-min';
import {FormControl, FormGroup} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import BusinessCategoryMinified from '../../../shared/model/business-rules/business-category-min';
import {
  CellDoubleClickedEvent,
  ColDef,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowValueChangedEvent
} from 'ag-grid-community';
import BusinessCategoryRuleDisplayMin from '../../../shared/model/business-rules/business-category-rule-display-min';
import UserDisplay from '../../../shared/model/user/user-display';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import {SelectListOption} from '@bmw-ds/components';
import {I18nProvider} from '../../../service/translations/i18n.service';
import BusinessRule from "../../../shared/model/business-rules/business-rule";
import RegionPropsMinified from '../../../shared/model/requests/region-props-min';
import BusinessRuleRegion from '../../../shared/model/business-rules/business-rule-region';
import {ToastService} from '../../../service/feedback/toast.service';
import Application from "../../../shared/model/applications/application";
import Location from "../../../shared/model/requests/location";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {LocationHttpService} from "../../../service/http/location-http.service";
import {DialogService} from "../../../service/modal/dialog.service";

@Component({
  selector: 'app-region-business-rule',
  templateUrl: './region-business-rule.component.html',
  styleUrls: ['./region-business-rule.component.scss']
})
export class RegionBusinessRuleComponent implements OnInit {
  selectedRegion: Region;
  loggedInUserRegions: Region[] = [];
  locations: Location[] = [];
  businessCategories: BusinessCategoryMinified[];
  allApplications: Application[] = [];
  showBusinessRules = false;
  editMode = false;
  showApps = false;
  regionIds: number[];
  regions: RegionMinified[] = [];
  selectedRegionId = new FormControl();
  editType: 'fullRow' = 'fullRow';
  tableData: BusinessCategoryRuleDisplayMin[] = [];
  columns = this.getColumns();
  gridApi!: GridApi<BusinessCategoryRuleDisplayMin>;
  showUpdateRule = new Map<number, boolean>();
  rowSelection: 'single' | 'multiple' = 'single';

  public rowGroupPanelShow: "always" | "onlyWhenGrouping" | "never" = "always";
  public defaultColDef: ColDef = {
    resizable: true,
  };

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  rowEvents: GridOptions = {
    onRowValueChanged: (event: RowValueChangedEvent) => this.updateRuleName(event),
    onCellDoubleClicked: (event: CellDoubleClickedEvent) => this.updateRuleStatus(event)
  }

  paginationSize: string | number;
  paginationSizes: any[] = [
    { id: 5},
    { id: 10},
    { id: 25},
    { id: 40},
    { id: 50},
    { id: 100}
  ];

  constructor(private regionHttpService: RegionHttpService, private sweetAlertService: SweetAlertService,
              private businessRuleHttpService: BusinessRuleHttpService, private toastService: ToastService,
              private businessRulesService: BusinessRuleHttpService, private userHttpService: UserHttpService,
              private businessCategoryHttpService: BusinessCategoryHttpService, private i18nProvider: I18nProvider,
              private locationHttpService: LocationHttpService, private dialog: DialogService) {}

  ngOnInit() {
    this.initScreen();
    this.getRegions();
  }

  private initScreen(){
    this.userHttpService.getCurrentUserRegions().subscribe(regions => {
        this.loggedInUserRegions = regions;
        this.regionIds = regions.map(region => +region?.id);
        this.getAllBusinessCategories();
        this.setupRegionSelectionListener();
        // this.onRegionSelectionChange();
      },
      error => this.sweetAlertService.showComError('Unable to retrieve logged in user: ' + error));
  }
  private setupRegionSelectionListener() {
    this.selectedRegionId.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(400)
      )
      .subscribe(regionId => {
        if (regionId) {
          this.handleRegionSelection(regionId);
        } else {
          this.locations = [];
          this.showBusinessRules = false;
          this.showApps = false;
        }
      });
  }
  private handleRegionSelection(regionId: number) {
    this.selectedRegion = this.loggedInUserRegions.find(region => region?.id === +regionId);

    if (this.selectedRegion) {
      // console.log('Region selected:', this.selectedRegion.name);
      // this.showBusinessRules = false;
      // this.showApps = false;
    this.locationHttpService.getLocationsForRegion(this.selectedRegion, false)
        .subscribe(
          locations => {
            this.processLocations(locations);
            this.updateBusinessRulesForRegion(regionId);
            this.showBusinessRules = true;
            this.calculateSelectedApplicationsForRegion();
            this.showApps = true;
            this.refreshBusinessRuleData();
          },
          error => {
            this.sweetAlertService.showComError('Error fetching locations: ' + error);
          }
        );
    }
  }
  private processLocations(locations: Location[]) {
    this.locations = locations.map(location => ({
      ...location,
      status: location.active
        ? this.i18nProvider.getDescription('active')
        : this.i18nProvider.getDescription('inactive')
    }));

    this.locations.sort((a, b) => a.name.localeCompare(b.name));
    if (this.gridApi) {
      const currentColumns = this.getColumns();
      const locationColumns = this.locations.map(location => ({
        field: location.id.toString(),
        headerName: location.name,
        filter: true,
        sortable: true,
        width: 5,
        cellRenderer: (params) => this.setStatusCheckbox(params)
      }));

      this.gridApi.setColumnDefs([...currentColumns, ...locationColumns]);
      this.gridApi.sizeColumnsToFit();
    }
  }

  private updateBusinessRulesForRegion(regionId: number) {
    this.businessCategories.forEach(category => {
      category.businessRules.forEach(rule => {
        rule.selected = rule?.regionIds.includes(+regionId);
      });
    });

    this.showBusinessRules = true;
  }


  private getLocationsByRegion(region: Region) {
    this.locationHttpService.getLocationsForRegion(region, false)
      .subscribe(locations => {
        locations.forEach(location => {
          if (location.active) {
            location.status = this.i18nProvider.getDescription('active');
          } else {
            location.status = this.i18nProvider.getDescription('inactive');
          }
        });
        this.locations = locations;
        this.locations.sort((a, b) => a.name.localeCompare(b.name));
        this.setLocationColumns();
      });
  }

  private getAllBusinessCategories() {
    this.businessCategoryHttpService.getActiveMinifiedBusinessCategoriesWithRules().subscribe(successResponse => {
      this.businessCategories = successResponse.sort((a, b) => a.name.localeCompare(b.name));
      this.businessCategories.forEach(businessCategory => businessCategory.businessRules.sort((a, b) => a.name.localeCompare(b.name)));
      // this.businessCategoryList = this.businessCategories.map(category => {
      //   return {id: category.id.toString(), label: category.name};
      // });
      this.paginationSize = this.setPaginationSize();
      this.tableData = this.setTableData();
      this.setLocationColumns();

    }, (error => {
      this.sweetAlertService.showComError('Error retrieving BusinessCategories: ' + JSON.stringify(error));
    }));
  }

  getBusinessCategories(){
    this.businessCategoryHttpService.getMinfiedBusinessCategoriesWithRules().subscribe(successResponse => {
      this.businessCategories = successResponse;
      if (this.businessCategories) {
        this.businessCategories.sort((a, b) => a.name.localeCompare(b.name));
      }
      this.paginationSize = this.setPaginationSize();
      this.tableData = this.setTableData();
    }, (error) => {
      this.sweetAlertService.showComError('Error retrieving businessCategories: ' + JSON.stringify(error));
    });
  }

  updateBusinessRules() {
    this.regionHttpService.getRegionById(this.selectedRegionId.value).subscribe(region =>
    {
      region.businessRules = [];
      this.businessCategories.forEach(masterBusinessCategory => {
        masterBusinessCategory.businessRules.forEach(masterBusinessRule => {
          if (masterBusinessRule.selected) {
            region.businessRules.push(masterBusinessRule);
          }
        });
      });
      this.regionHttpService.updateRegion(region).subscribe(() => {
        this.showBusinessRules = true;
        // this.selectedRegionId.reset();
      });
    });

  }
  private setLocationColumns() {
    const colDefs = this.gridApi.getColumnDefs();
    this.locations.forEach(location => {
      colDefs.push({field: location.id.toString(), headerName: location.name, filter: true, sortable: true, width: 5,
        cellRenderer: (params) => {return this.setStatusCheckbox(params);}});
    });
    this.gridApi.setColumnDefs(colDefs);
  }

  setStatusCheckbox(params) {
    if (params && params.data && params.data.regions && params.data.regions.includes(Number(params.colDef.field)) && params.data.active) {
      return '&#10003;';
    } else {
      return '&#88;';
    }
  }

  onRegionSelectionChange(){
    this.selectedRegionId.valueChanges
      .pipe( distinctUntilChanged(), debounceTime(400))
      .subscribe(regionId => {
        if (regionId){
          this.selectedRegion = this.loggedInUserRegions.find(region => region?.id === +regionId);
          this.businessCategories.forEach(category => {
            category.businessRules.forEach(rule => {
              const isAvailableInRegion = rule?.regionIds.find(id => id === +regionId);
              isAvailableInRegion ? rule.selected = true : rule.selected = false;
            });
          });
          this.showBusinessRules = !!this.selectedRegion;
        }
      });
  }
  updateRuleStatus(rowObject: any) {
    if (rowObject.column.colId !== 'business_category_name' && rowObject.column.colId !== 'business_rule_name') {

      const locationId = parseInt(rowObject.column.colId);
      const isCurrentlyActive = rowObject.data.regions.includes(locationId);

      let businessRuleRegion = new BusinessRuleRegion();
      businessRuleRegion.businessRuleId = rowObject.data.business_rule_id;
      businessRuleRegion.regionId = this.selectedRegion.id;
      businessRuleRegion.locationId = locationId;
      businessRuleRegion.active = !isCurrentlyActive;


      this.businessRuleHttpService.linkBusinessRuleToRegion(businessRuleRegion).subscribe(
        () => {
          if (businessRuleRegion.active) {
            if (!rowObject.data.regions.includes(locationId)) {
              rowObject.data.regions.push(locationId);
            }
          } else {
            const index = rowObject.data.regions.indexOf(locationId);
            if (index > -1) {
              rowObject.data.regions.splice(index, 1);
            }
          }

          let refreshParams = {
            force: true,
            rowNodes: [rowObject.node],
            columns: [rowObject.column.colId]
          };
          rowObject.api.refreshCells(refreshParams);

          this.refreshBusinessRuleData();

          this.toastService.success(
            `Business Rule [${rowObject.data.business_rule_name}] linkage to Location: [${rowObject.column.colDef.headerName}] updated.`
          );
        },
        (error) => {
          console.error('Error in update:', error);
          this.sweetAlertService.showComError('Error updating Business Rule - Region linkage: ' + JSON.stringify(error));
        }
      );
    }
  }
  private refreshBusinessRuleData() {
    if (this.selectedRegion?.id) {
      this.businessRuleHttpService.getBusinessRuleLocations(this.selectedRegion.id)
        .subscribe(
          (relationships) => {
            const uniqueRelationships = Array.from(
              new Map(
                relationships.map(rel =>
                  [`${rel.businessRuleId}-${rel.locationId}`, rel]
                )
              ).values()
            );

            this.tableData.forEach(row => {
              row.regions = uniqueRelationships
                .filter(rel =>
                  rel.businessRuleId === row.business_rule_id &&
                  rel.active
                )
                .map(rel => rel.locationId);
            });

            this.gridApi.setRowData(this.tableData as any);
          },
          (error) => {
            console.error('Error refreshing business rule data:', error);
            this.sweetAlertService.showComError('Error refreshing business rule data: ' + JSON.stringify(error));
          }
        );
    }
  }

  updateRuleName(rowObject: any) {
    let businessRule = new BusinessRule();
    let data = rowObject.data;
    businessRule.id = data.business_rule_id;
    businessRule.name = data.business_rule_name;
    businessRule.active = data.active;

    this.businessRulesService.updateExistingBusinessRule(businessRule).subscribe(() => {
      this.showUpdateRule.set(businessRule.id, false);
    }, (error) => {
      this.sweetAlertService.showComError('Error retrieving businessRule: ' + JSON.stringify(error));
    });

  }

  setTableData(): any[] {
    const dataRows: BusinessCategoryRuleDisplayMin[] = [];
    if (this.businessCategories) {
      this.businessCategories.forEach(businessCategory => {
        businessCategory.businessRules.forEach(businessRule => {
          let displayBusinessRule = new BusinessCategoryRuleDisplayMin();
          displayBusinessRule.business_category_id = businessCategory.id;
          displayBusinessRule.business_category_name = businessCategory.name;
          displayBusinessRule.business_rule_id = businessRule.id;
          displayBusinessRule.business_rule_name = businessRule.name;
          displayBusinessRule.active = businessRule.active;
          displayBusinessRule.regions = businessRule.regionIds;
          displayBusinessRule.enabledForRegion = businessRule.regionIds.includes(this.selectedRegionId?.value);
          dataRows.push(displayBusinessRule);
          displayBusinessRule = null;
        });
      });
    }
    return dataRows;
  }

  getRegions(){
    this.regionHttpService.getRegionsMinified().subscribe(response => {
      this.regions = response.filter(region => {
        return this.regionIds.includes(region?.id);
      });
    });
  }

  private calculateSelectedApplicationsForRegion() {
    this.allApplications.forEach(application => {
      application.enabledForRegion = false;
      application.status = this.i18nProvider.getDescription('inactive');
      this.selectedRegion.applications.forEach(regionApplication => {
        if (application.id === regionApplication.id) {
          application.enabledForRegion = true;
          application.status = this.i18nProvider.getDescription('active');
        }
      });
    });
    // this.gridApi.setRowData(this.allApplications);
  }
  getColumns(){
    return [
      {field: 'business_category_name', headerName: this.i18nProvider.getDescription('category'), filter: true, sortable: true,
        width: 150, enableRowGroup: false, rowGroup: false, hide: true},
      {field: 'business_rule_name', headerName: this.i18nProvider.getDescription('business_rule_name'), filter: true, sortable: true,
        width: 150, editable: false, enableRowGroup: false},
      {
        field: 'active',
        headerName: this.i18nProvider.getDescription('status'),
        filter: true,
        width: 90,
        sortable: true,
        cellRenderer: (params) => {
          if (!params.data) {
            return '';
          }
          const isActive = params.data.active;
          const statusText = isActive ?
            this.i18nProvider.getDescription('active') :
            this.i18nProvider.getDescription('inactive');

          return `<span class="${isActive ? 'active' : 'inactive'}">${statusText}</span>`;
        },
        cellClass: (params) => {
          if (!params.data) {
            return 'inactive';
          }
          return params.data.active ? 'active' : 'inactive';
        }
    }];

  }
  changeRegion() {
    this.calculateSelectedApplicationsForRegion();
    this.editMode = false;
    this.showApps = true;
  }


  onGridReady(params: GridReadyEvent<BusinessCategoryRuleDisplayMin>) {
    this.gridApi = params.api;
  }

  onPageSizeChanged(size: any) {
    localStorage.setItem('ara_manage_business_rules_pagination', size);
    this.paginationSize = size;
  }

  setPaginationSize() {
    if (localStorage.getItem('ara_manage_business_rules_pagination')) {
      return localStorage.getItem('ara_manage_business_rules_pagination');
    }
    return 40;
  }

  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }

}
