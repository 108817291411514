import {Component, OnInit} from '@angular/core';
import {UserHttpService} from 'src/app/service/http/user-http.service';
import {RequestHttpService} from 'src/app/service/http/request-http.service';
import {LookupHttpService} from 'src/app/service/http/lookup-http.service';
import BmwPerson from 'src/app/shared/model/user/bmw-person';
import {NavigationExtras, Router} from '@angular/router';
import RequestsForRightsManagerDto from 'src/app/shared/model/requests/requests-for-rights-manager-dto';
import {I18nProvider} from '../../../service/translations/i18n.service';
import {
  CellClickedEvent,
  ColDef,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  RowDoubleClickedEvent
} from 'ag-grid-community';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import {formatDate} from '@angular/common';
import Application from '../../../shared/model/applications/application';
import RegionPropsMinified from '../../../shared/model/requests/region-props-min';
import User from '../../../shared/model/user/user';
import { DsBannerModule } from '@bmw-ds/components';
import Modal from "../../../shared/model/modal";
import {MessageComponent} from "../../../components/popup/message/message.component";
import {DialogService} from "../../../service/modal/dialog.service";
@Component({
  selector: 'app-rights-manager-current',
  templateUrl: './rights-manager-current.component.html',
  styleUrls: ['./rights-manager-current.component.scss']
})
export class RightsManagerCurrentComponent implements OnInit{

  groupedRequests: RequestsForRightsManagerDto[] = [];
  columns = this.getTableColumns();
  employees: BmwPerson[];
  requests: Map<string, RequestsForRightsManagerDto> = new Map<string, RequestsForRightsManagerDto>();
  gridApi!: GridApi<RequestsForRightsManagerDto>;
  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  rowEvents: GridOptions = {
    onRowDoubleClicked: (event: RowDoubleClickedEvent) => this.openRightManagerDetails(event.data),
    onCellClicked: (event: CellClickedEvent) => {
    },
  };
  paginationSize: string | number;
  paginationSizes: any[] = [
    { id: 10},
    { id: 25},
    { id: 40},
    { id: 50},
    { id: 100}
  ];
  public rowGroupPanelShow: "always" | "onlyWhenGrouping" | "never" = "always";
  public defaultColDef: ColDef = {
    resizable: true,
  };
  lg: any;
  displayAllRequests;
  loggedInUser: User;
  allRequests: RequestsForRightsManagerDto[] = [];
  requestAccess = false;

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    if (localStorage.getItem('ara-rights-manager-current')) {
      if (Object.keys(JSON.parse(localStorage.getItem('ara-rights-manager-current'))).length > 0) {
        params.api.setFilterModel(JSON.parse(localStorage.getItem('ara-rights-manager-current')));
      }
      this.displayStatus();
    }
  }

  onFilterChanged(params) {
    if (!params.afterDataChange) {
      localStorage.setItem('ara-rights-manager-current', JSON.stringify(params.api.getFilterModel()));
    }
  }

  constructor(private userHttpService: UserHttpService, private requestService: RequestHttpService,
              private lookupHttpService: LookupHttpService, public router: Router, private i18nProvider: I18nProvider, private dialog: DialogService) {
  }
  async ngOnInit(): Promise<void> {
    this.loggedInUser = await this.userHttpService.getLoggedInUser(false).toPromise();
    this.initOpenRequestsForRightsManager();
    this.paginationSize = this.setPaginationSize();
    this.requestAccess = this.loggedInUser.requestsAccess;
    this.displayAllRequests = this.loggedInUser.applications.length <= 0;
  }

  private initOpenRequestsForRightsManager(resetObservable?: boolean) {
    this.requestService.getMultipleOpenRequestsAwaitingRightsManager(resetObservable).subscribe(requests => {
      this.allRequests = requests;
      this.getTableData(requests);
    });
  }

  getTableColumns() {
    return  [
      {field: 'name', headerName: this.i18nProvider.getDescription('surname_name'), width: 350, filter: true, sortable: true},
      {field: 'qnumber', headerName: this.i18nProvider.getDescription('q_number'), width: 200, filter: true, sortable: true},
      {field: 'regionName', headerName: this.i18nProvider.getDescription('region_name'), width: 200, filter: true, sortable: true, enableRowGroup: true},
      {field: 'defaultLocation', headerName: this.i18nProvider.getDescription('default_location'), width: 200, filter: true, sortable: true, enableRowGroup: true},
      {field: 'department', headerName: this.i18nProvider.getDescription('department_code'), width: 200, filter: true, sortable: true, enableRowGroup: true},
      {field: 'workflowDate', headerName: this.i18nProvider.getDescription('workflow_date'), width: 200, filter: true, sortable: true, cellRenderer: (data) => {
          if (data.value) return formatDate(data.value, 'dd.MM.yyyy', 'de-DE');
        }},
      // {field: 'startDate', headerName: this.i18nProvider.getDescription('start_date'), width: 200, filter: true, sortable: true, cellRenderer: (data) => {
      //     if (data.value) return formatDate(data.value, 'dd.MM.yyyy', 'de-DE');
      //   }},
      {field: 'appNames', headerName: this.i18nProvider.getDescription('application_name'), width: 650, filter: true, sortable: true}
    ];
  }

  getTableData(requests: RequestsForRightsManagerDto[]) {
    let filteredRequest: RequestsForRightsManagerDto[] = [];
    this.groupedRequests = [];
    this.requests = new Map<string, RequestsForRightsManagerDto>();
    if (!this.displayAllRequests && this.loggedInUser.applications.length > 0) {
      requests.forEach(request => {
        this.loggedInUser.applications.forEach(application => {
          if (request.appNames.includes(application.name)) {
            filteredRequest.push(request);
          }
        });
      });
    } else if (this.displayAllRequests) {
      filteredRequest = requests;
    }

    filteredRequest.forEach(item => {
      const request = this.requests.get(item.qnumber) || item;
      request.appNames = [...new Set([...request.appNames, ...item.appNames])];
      if (!request.regionShortCode.includes(item.regionShortCode)){
        request.regionShortCode = request.regionShortCode + `, ${item.regionShortCode}`;
        request.regionName = request.regionName + `, ${item.regionName}`;
      }

      if (!this.requests.has(item.qnumber)) {
        this.requests.set(item.qnumber, request);
      }
    });

    this.groupedRequests = Array.from(this.requests.values()).map(request => ({
      ...request,
    }));

    this.displayStatus();
  }

  displayStatus(){

    const colDefs = this.gridApi.getColumnDefs();
    const columnExists = (field: string) => colDefs.some(col => 'field' in col && col.field == field);
    this.groupedRequests.forEach(request => {
      if (request.inRemovalDate != null && !columnExists('endDate')) {

        colDefs.push(  {field: 'endDate', headerName: this.i18nProvider.getDescription('end_date'), filter: true, sortable: true, width: 200,
          cellRenderer: (params) => {
            if (params.data.inRemovalDate != null) {
              return formatDate(params.data.inRemovalDate, 'dd.MM.yyyy', 'en-GB');
            }
          }
        });
        // colDefs.splice(6, 0, setData);
        this.gridApi.setColumnDefs(colDefs);
      }
       if (request.changesMadeDate != null && !columnExists('changesMadeDate')) {
        colDefs.push(  {field: 'changesMadeDate', headerName: this.i18nProvider.getDescription('request_change_made'), filter: true, sortable: true, width: 200,
          cellRenderer: (params) => {
            if (params.data.changesMadeDate != null) {
              return formatDate(params.data.changesMadeDate, 'dd.MM.yyyy', 'en-GB');
            }
          }
        });
        this.gridApi.setColumnDefs(colDefs);
      }
      if ( request.inProgressDate != null && !columnExists('startDate')){
        colDefs.push(  {field: 'startDate', headerName: this.i18nProvider.getDescription('request_start_date'), filter: true, sortable: true, width: 200,
          cellRenderer: (params) => {
            if (params.data.inProgressDate != null) {
              return formatDate(params.data.inProgressDate, 'dd.MM.yyyy', 'en-GB');
            }
          }
        });
        this.gridApi.setColumnDefs(colDefs);
      }

    });
  }
  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }
  openRightManagerDetails(openReq: RequestsForRightsManagerDto) {
    if (openReq.qnumber == this.loggedInUser.qnumber  && openReq.qnumber.toLowerCase() != 'q253673' && openReq.qnumber.toLowerCase() != 'qxz4zc0' && openReq.qnumber.toLowerCase() != 'qxz41ls'  && openReq.qnumber.toLowerCase() != 'qxz3a7r'){
      this.ownRequests();
    } else {
      const navigationExtras: NavigationExtras = {
        state: {
          qNumber: openReq.qnumber
        },
      };
      this.router.navigate(['/rights-manager-request-details'], navigationExtras);
    }
  }

  setPaginationSize() {
    if (localStorage.getItem('ara_rights_manager_current_pagination')) {
      return localStorage.getItem('ara_rights_manager_current_pagination');
    }
    return 40;
  }

  onPageSizeChanged(size: any) {
    localStorage.setItem('ara_rights_manager_current_pagination', size);
    this.paginationSize = size;
  }

  refreshScreen() {
    localStorage.setItem('ara-rights-manager-current', JSON.stringify({}));
    this.initOpenRequestsForRightsManager(true);
    this.gridApi.setFilterModel(null);
  }

  filterRequests(value: boolean) {
    this.displayAllRequests = value;
    this.getTableData(this.allRequests);
    this.displayStatus();

  }

  ownRequests() {
    const data: Modal = {
      action : 'current_requests',
      message: 'own_work_not_allowed'
    };
    this.dialog.open(MessageComponent, {data});
  }
}
