import {Component, OnInit} from '@angular/core';
import BusinessCategory from 'src/app/shared/model/business-rules/business-category';
import {BusinessCategoryHttpService} from 'src/app/service/http/business-category-http.service';
import {SweetAlertService} from 'src/app/service/feedback/SweetAlertService.service';
import BusinessRule from 'src/app/shared/model/business-rules/business-rule';
import {BusinessRuleHttpService} from 'src/app/service/http/business-rule-http.service';
import {Observable} from 'rxjs';
import BusinessCategoryMinified from '../../../shared/model/business-rules/business-category-min';
import BusinessRuleMinified from '../../../shared/model/business-rules/business-rule-min';
import {
  CellClickedEvent, CellDoubleClickedEvent, ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  PaginationChangedEvent,
  RowDoubleClickedEvent
} from 'ag-grid-community';
import Application from '../../../shared/model/applications/application';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import {I18nProvider} from '../../../service/translations/i18n.service';
import {DialogService} from '../../../service/modal/dialog.service';
import Modal from '../../../shared/model/modal';
import {ConfirmationComponent} from '../../../components/popup/confirmation/confirmation.component';
import RegionMinified from "../../../shared/model/requests/region-min";
import {RegionHttpService} from "../../../service/http/region-http.service";
import {UserHttpService} from "../../../service/http/user-http.service";
import {UserRole} from "../../../shared/enums/user-role";
import {user} from "@bmw-ds/components/spriteTree";
import User from "../../../shared/model/user/user";
import {ToastService} from '../../../service/feedback/toast.service';
@Component({
  selector: 'app-business-category',
  templateUrl: './region-business-category.component.html',
  styleUrls: ['./region-business-category.component.scss']
})
export class RegionBusinessCategoryComponent implements OnInit {
  auth = true;
  showAdd = true;
  showCategory = false;
  isDisabled = true;
  businessCategories: BusinessCategoryMinified[];
  regions: RegionMinified[] = [];
  loggedInUserRegions: RegionMinified[] = [];
  regionIds: number[] = [];
  private loggedInUser: User;
  selectedRegion: RegionMinified | null = null;
  newBusinessRulesIds: number;
  businessRuleToAdd: BusinessRule;
  businessRules: BusinessRuleMinified[] = [];
  showBusinessRuleList = false;
  selectedBusinesscategory: BusinessCategory;
  businessCategoriesName: string;
  showUpdateCategory = new Map<number, boolean>();
  columns = [];
  updateModalIsOpen = false;
  modalIsOpen = false;
  editMode = false;
  rowSelection: 'single' | 'multiple' = 'single';
  editType: 'fullRow' = 'fullRow';
  public defaultColDef: ColDef = {
    resizable: true,
  };
  gridApi!: GridApi<BusinessCategoryMinified>;
  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  public rowGroupPanelShow: "always" | "onlyWhenGrouping" | "never" = "always";
  rowEvents: GridOptions = {
    // onRowDoubleClicked: (event: RowDoubleClickedEvent) => {
    //   this.selectedBusinesscategory = event.data;
    //   this.editMode = true;
    // },
    // onRowClicked: (event: CellClickedEvent) => {
    //   this.selectedBusinesscategory = event.data;
    //   this.editMode = true;
    // },
    // onPaginationChanged: (event: PaginationChangedEvent) => {
    //   this.selectedBusinesscategory = null;
    //   this.editMode = false;
    // },
    onCellDoubleClicked: (event: CellDoubleClickedEvent) => this.updateLocationStatus(event)
  };
  constructor(private businessCategoryHttpService: BusinessCategoryHttpService,
              private sweetAlertService: SweetAlertService ,
              private regionHttpService: RegionHttpService,
              private userHttpService: UserHttpService,
              private businessRuleHttpService: BusinessRuleHttpService, private i18nProvider: I18nProvider,
              private toastService: ToastService) {
  }

  ngOnInit() {
    this.loadBusinessCategories();
    this.loadUserRegions();

    (window as any).dispatchToggleEvent = (nodeId: string, colField: string, newActive: boolean) => {
      if (this.gridApi){
        const rowNode = this.gridApi.getRowNode(nodeId);
        if (rowNode) {
          this.updateLocationStatus({
            data: rowNode.data,
            colDef: {field: colField},
            newActive,
            api: this.gridApi,
            node: rowNode,
          });
        }
      }
    };
  }
  loadBusinessCategories() {
    this.getBusinessCategories().subscribe(
      successResponse => {
        this.businessCategories = successResponse.sort((a, b) => a.name.localeCompare(b.name));
        this.getColumns();
        this.setBusinessRules();
      },
      error => this.sweetAlertService.showComError('Error retrieving business categories')
    );
  }

  loadUserRegions() {
    this.userHttpService.getLoggedInUser(false).subscribe(
      // tslint:disable-next-line:no-shadowed-variable
      user => {
        this.loggedInUser = user;
        const regions = user.roles
          .filter(role => role.name === UserRole.ARA_REGION_ADMIN)
          .map(role => role.region)
          .filter(region => region && region.active && region.name);
        this.regionIds = regions.map(region => region.id);
        this.fetchRegions();
      },
      error => this.sweetAlertService.showComError('Unable to retrieve logged in user')
    );
  }

  fetchRegions() {
    this.regionHttpService.getRegionsMinified().subscribe(
      regions => {
        this.regions = regions.filter(region => this.regionIds.includes(region.id));
      },
      error => {
        this.sweetAlertService.showComError('Error retrieving regions: ' + JSON.stringify(error));
      }
    );
  }

  selectRegions(region: RegionMinified) {
    this.selectedRegion = region;
    this.showCategory = !!region;
    if (this.gridApi) {
      this.calculateSelectedApplicationsForRegion();
      this.setLocationsColumns();
    }
  }
  private setBusinessRules() {
    this.businessCategories.forEach(category => {
      category.status = category.active
        ? this.i18nProvider.getDescription('active') : this.i18nProvider.getDescription('inactive');
      category.businessRules.forEach(businessRule => this.businessRules.push(businessRule));
    });
    this.businessRules.sort((a, b) => a.name.localeCompare(b.name));
  }

  private getBusinessCategories(): Observable<BusinessCategoryMinified[]>  {
    return this.businessCategoryHttpService.getMinfiedBusinessCategoriesWithRules();
  }

  addCategory() {
    const businessCategory = new BusinessCategory();
    businessCategory.name = this.businessCategoriesName;
    businessCategory.status = 'active';
    businessCategory.active = true;
    this.businessCategoryHttpService.createBusinessCategory(businessCategory).subscribe(successResponse => {
      // @ts-ignore
      this.businessCategories.push(successResponse);
      this.gridApi.setRowData(this.businessCategories);
      this.modalIsOpen = false;
      this.businessCategoriesName = '';
      this.showCategory = false;
    }, (error) => {
      this.sweetAlertService.showComError('Error retrieving businessCategories: ' + JSON.stringify(error));
    });
  }
  changeStatus(value: boolean){
    this.selectedBusinesscategory.active = value;
    this.updateCategory(this.selectedBusinesscategory);
  }
  updateCategory(businessCategory: BusinessCategory) {
    this.businessCategoryHttpService.updateBusinessCategory(businessCategory).subscribe(successResponse => {
      this.showUpdateCategory.set(businessCategory.id, false);
      if (this.selectedBusinesscategory.active){
        this.selectedBusinesscategory.status = this.i18nProvider.getDescription('active');
      }
      else {
        this.selectedBusinesscategory.status = this.i18nProvider.getDescription('inactive');
      }
      this.businessCategories.filter(category => category.id !== this.selectedBusinesscategory.id);
      this.gridApi.setRowData(this.businessCategories);
      this.updateModalIsOpen = false;
    }, (error) => {
      this.sweetAlertService.showComError('Error retrieving businessCategories: ' + JSON.stringify(error));
    });
  }


  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }

  private calculateSelectedApplicationsForRegion() {
    if (!this.gridApi || !this.selectedRegion) { return; }
    const updateCategories = this.businessCategories.map(category => {
      const isLinkedToRegion = this.selectedRegion.locations.some(loc => loc.id == category.id);
      category.enabledForRegion = isLinkedToRegion;
      category.status = isLinkedToRegion
      ? this.i18nProvider.getDescription('active')
        : this.i18nProvider.getDescription('inactive');
      return category;
    });
    this.gridApi.setRowData(updateCategories);
  }

  onGridReady(params: GridReadyEvent<BusinessCategoryMinified>) {
    this.gridApi = params.api;
    this.setLocationsColumns();
  }
  getColumns(){
    this.columns = [
      {
        field: 'name',
        headerName: this.i18nProvider.getDescription('application_name'),
        filter: true,
        sortable: true,
        width: 300
      },
    ];
  }
  changeRegion() {
   if (this.selectedRegion) {
     this.showCategory = true;
     //this.gridApi.setRowData([]);
     this.setLocationsColumns();
     this.calculateSelectedApplicationsForRegion();
   } else {
     this.showCategory = false;
   }
  }
  private setLocationsColumns() {
    if (!this.gridApi || !this.selectedRegion) { return; }
    const baseColumns = [
      {
        field: 'name',
        headerName: this.i18nProvider.getDescription('business_category_name'),
        filter: true,
        sortable: true,
        width: 150,
      }
    ];
    const locationColumns = this.selectedRegion.locations.map(location => ({
      field: location.id.toString(),
      headerName: location.name,
      filter: true,
      sortable: true,
      width: 150,
      cellRenderer: params => {return this.setStatusCheckboxes(params)},
    }));
    this.gridApi.setColumnDefs([...baseColumns, ...locationColumns]);

  }
  setStatusCheckboxes(params) {
    const locationId = Number(params.colDef.field);
    const location = params.data.linkedLocations?.find(
      (loc) => loc.locationId == locationId
    );

    const isActive = location?.active || false;
    const icon = isActive ? '&#10003;' : '&#88;';
    return icon;
    // return `
    //   <span style="cursor: pointer;"
    //         onclick="window.dispatchToggleEvent('${params.node.id}', '${params.colDef.field}', ${!isActive})">
    //         ${icon}
    //         </span>
    // `
  }
  updateLocationStatus(rowObject: any) {
    if (rowObject.column.colId !== 'name') {
      const locationId = Number(rowObject.colDef.field);
      if (!rowObject.data.linkedLocations) {
        rowObject.data.linkedLocations = [];
      }
      let location = rowObject.data.linkedLocations.find(
        (loc) => loc.locationId == locationId
      );
      const newActive = !location.active;
      if (!location) {
        location = {id: null, businessCategoryId: rowObject.data.id, locationId, active: newActive};
        rowObject.data.linkedLocations.push(location);
      } else {
        location.active = newActive;
      }

      this.businessCategoryHttpService.linkLocationToBusinessCategory(rowObject.colDef.field, rowObject.data.id).subscribe(() => {
          const refreshParams = {force: true, rowNodes: [rowObject.node], columns: [rowObject.colDef.field]};
          rowObject.api.refreshCells(refreshParams);

          this.toastService.success('Business Rule Category [' + rowObject.data.name + '] linkage to Region Location:  [' + rowObject.column.colDef.headerName + '] updated successfully.');
        },
        (error) => {
          //location.active = !newActive;
          //rowObject.api.refreshCells(refreshParams);
          this.sweetAlertService.showComError('Error updating location status: ' + JSON.stringify(error));
        }
      );
    }
  }
}
