<div class="container-costom">
  <div class="row">
    <div class="col-md-12 mt-5">
      <h1 class="h1-heading">
        <span class = "light-orange">{{'' | i18n: 'manage_business_rule_categories'}}</span>
      </h1>
    </div>
  </div>
  <div class="row" style = "margin-top: 30px">
    <div class = "col-md-6 ">
      <div class="form-group">
        <label class = "select-region">{{'' | i18n: 'choose_region'}}:</label>
        <div class = "">
          <select [(ngModel)]="selectedRegion" (change)="changeRegion()">
            <option value="-1" selected disabled>{{'' | i18n: 'choose_region'}}</option>
            <option *ngFor="let region of regions" [ngValue]="region">{{region?.name}}</option>
          </select>
        </div>
      </div>
    </div>

  </div>
<div class="row" *ngIf="showCategory">
  <div class="container-costom">

    <div class="col-12">

      <div class="flex-container">
        <ag-grid-angular
          class="ag-theme-alpine ag-grid-full-height-width"
          rowHeight="35"
          [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
          [pagination]="true"
          [suppressDragLeaveHidesColumns]="true"
          [paginationPageSize]="15"
          [rowData]="businessCategories"
          [rowGroupPanelShow]="rowGroupPanelShow"
          [rowSelection]="rowSelection"
          [editType]="editType"
          (cellDoubleClicked)="rowEvents.onCellDoubleClicked($event)"
          [columnDefs]="columns"
          [defaultColDef]="defaultColDef"
          (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
  </div>
</div>
<ds-modal #update size="sm">
  <ds-box-header>{{'' | i18n: 'edit'}} {{'' | i18n: 'business_category'}}</ds-box-header>
  <ds-box-content *ngIf="selectedBusinesscategory">
    <ds-form-field>
      <label ds-label >{{'' | i18n: 'region_name'}}</label>
      <input ds-input type="text" [(ngModel)]="selectedBusinesscategory.name" required/>
    </ds-form-field>
  </ds-box-content>
  <ds-box-footer>
    <button ds-button variant="outline"  #dsModalDismissElement>{{'' | i18n: 'cancel'}}</button>
    <button ds-button (click)="updateCategory(selectedBusinesscategory)" [disabled]="!selectedBusinesscategory?.name">{{'' | i18n: 'save'}}</button>
  </ds-box-footer>
</ds-modal>

<ds-modal #add size="sm">
  <ds-box-header>{{'' | i18n: 'add'}} {{'' | i18n: 'business_category'}}</ds-box-header>
  <ds-box-content >
    <ds-form-field>
      <label ds-label >{{'' | i18n: 'region_name'}}</label>
      <input ds-input type="text" [(ngModel)]="businessCategoriesName" required/>
    </ds-form-field>
  </ds-box-content>
  <ds-box-footer>
    <button ds-button variant="outline"  #dsModalDismissElement>{{'' | i18n: 'cancel'}}</button>
    <button ds-button (click)="addCategory()" [disabled]="!businessCategoriesName">{{'' | i18n: 'save'}}</button>
  </ds-box-footer>
</ds-modal>

<ds-modal #rules size="sm">
  <ds-box-header>{{'' | i18n: 'add'}} {{'' | i18n: 'business_rules'}}</ds-box-header>
  <ds-box-content *ngIf="selectedBusinesscategory">
    <ds-list-item *ngFor="let rule of selectedBusinesscategory?.businessRules; let i = index">{{i+1}} - {{rule.name}}</ds-list-item>
  </ds-box-content>
  <ds-box-footer>
    <button ds-button variant="outline"  #dsModalDismissElement>{{'' | i18n: 'cancel'}}</button>
  </ds-box-footer>
</ds-modal>
