import {Component, OnInit} from '@angular/core';
import BusinessCategory from 'src/app/shared/model/business-rules/business-category';
import {BusinessCategoryHttpService} from 'src/app/service/http/business-category-http.service';
import {SweetAlertService} from 'src/app/service/feedback/SweetAlertService.service';
import BusinessRule from 'src/app/shared/model/business-rules/business-rule';
import {BusinessRuleHttpService} from 'src/app/service/http/business-rule-http.service';
import {Observable} from 'rxjs';
import BusinessCategoryMinified from '../../../shared/model/business-rules/business-category-min';
import BusinessRuleMinified from '../../../shared/model/business-rules/business-rule-min';
import {
  CellClickedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  PaginationChangedEvent,
  RowDoubleClickedEvent
} from 'ag-grid-community';
import Application from '../../../shared/model/applications/application';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import {I18nProvider} from '../../../service/translations/i18n.service';
import {DialogService} from '../../../service/modal/dialog.service';
import Modal from '../../../shared/model/modal';
import {ConfirmationComponent} from '../../../components/popup/confirmation/confirmation.component';
@Component({
  selector: 'app-business-category',
  templateUrl: './business-category.component.html',
  styleUrls: ['./business-category.component.scss']
})
export class BusinessCategoryComponent implements OnInit {
  auth = true;
  showAdd = true;
  showCategory = false;
  isDisabled = true;
  businessCategories: BusinessCategoryMinified[];
  newBusinessRulesIds: number;
  businessRuleToAdd: BusinessRule;
  businessRules: BusinessRuleMinified[] = [];
  showBusinessRuleList = false;
  selectedBusinesscategory: BusinessCategory;
  businessCategoriesName: string;
  showUpdateCategory = new Map<number, boolean>();
  columns = [];
  updateModalIsOpen = false;
  modalIsOpen = false;
  editMode = false;
  rowSelection: 'single' | 'multiple' = 'single';
  gridApi!: GridApi<BusinessCategoryMinified>;
  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  public rowGroupPanelShow: "always" | "onlyWhenGrouping" | "never" = "always";
  rowEvents: GridOptions = {
    onRowDoubleClicked: (event: RowDoubleClickedEvent) => {
      this.selectedBusinesscategory = event.data;
      this.editMode = true;
    },
    onRowClicked: (event: CellClickedEvent) => {
      this.selectedBusinesscategory = event.data;
      this.editMode = true;
    },
    onPaginationChanged: (event: PaginationChangedEvent) => {
      this.selectedBusinesscategory = null;
      this.editMode = false;
    }
  };
  constructor(private businessCategoryHttpService: BusinessCategoryHttpService,
              private sweetAlertService: SweetAlertService ,
              private businessRuleHttpService: BusinessRuleHttpService, private i18nProvider: I18nProvider) {
  }

  ngOnInit() {
    this.getBusinessCategories().subscribe(successResponse => {
      this.businessCategories = successResponse;
      this.getColumns();
      if (this.businessCategories) {
        this.businessCategories.sort((a, b) => a.name.localeCompare(b.name));
        this.setBusinessRules();
      }
    }, (error) => {
      this.sweetAlertService.showComError('Error retrieving businessCategories: ' + JSON.stringify(error));
    });
  }

  private setBusinessRules() {
    this.businessCategories.forEach(category => {
      category.status = this.i18nProvider.getDescription('inactive');
      if (category.active){
        category.status = this.i18nProvider.getDescription('active');
      }
      category.businessRules.forEach(businessRule => {
        this.businessRules.push(businessRule);
      });
    });

    if (this.businessRules.length > 0) {
      this.businessRules.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  private getBusinessCategories(): Observable<BusinessCategoryMinified[]>  {
    return this.businessCategoryHttpService.getMinfiedBusinessCategoriesWithRules();
  }

  addCategory() {
    const businessCategory = new BusinessCategory();
    businessCategory.name = this.businessCategoriesName;
    businessCategory.status = 'active';
    businessCategory.active = true;
    this.businessCategoryHttpService.createBusinessCategory(businessCategory).subscribe(successResponse => {
      // @ts-ignore
      this.businessCategories.push(successResponse);
      this.gridApi.setRowData(this.businessCategories);
      this.modalIsOpen = false;
      this.businessCategoriesName = '';
      this.showCategory = false;
    }, (error) => {
      this.sweetAlertService.showComError('Error retrieving businessCategories: ' + JSON.stringify(error));
    });
  }
  changeStatus(value: boolean){
    this.selectedBusinesscategory.active = value;
    this.updateCategory(this.selectedBusinesscategory);
  }
  updateCategory(businessCategory: BusinessCategory) {
    this.businessCategoryHttpService.updateBusinessCategory(businessCategory).subscribe(successResponse => {
      this.showUpdateCategory.set(businessCategory.id, false);
      if (this.selectedBusinesscategory.active){
        this.selectedBusinesscategory.status = this.i18nProvider.getDescription('active');
      }
      else {
        this.selectedBusinesscategory.status = this.i18nProvider.getDescription('inactive');
      }
      this.businessCategories.filter(category => category.id !== this.selectedBusinesscategory.id);
      this.gridApi.setRowData(this.businessCategories);
      this.updateModalIsOpen = false;
    }, (error) => {
      this.sweetAlertService.showComError('Error retrieving businessCategories: ' + JSON.stringify(error));
    });
  }

  addBusinessRule(businessCategory: BusinessCategory, businessRuleToAdd: any) {
    businessRuleToAdd.businessCategoryId = this.selectedBusinesscategory.id;
    businessCategory.businessRules.push(businessRuleToAdd);
    this.businessRuleHttpService.updateBusinessRule(businessRuleToAdd).subscribe(() => {
      this.getBusinessCategories().subscribe(successResponse => {
        this.businessCategories = successResponse;
        this.showBusinessRuleList = false ;
        this.showAdd = true;
      }, (error) => {
        this.sweetAlertService.showComError('Error retrieving businessCategories: ' + JSON.stringify(error));
      });
    });
  }

  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }

  onGridReady(params: GridReadyEvent<BusinessCategoryMinified>) {
    this.gridApi = params.api;
  }
  getColumns(){
    this.columns = [
      {
        field: 'name',
        headerName: this.i18nProvider.getDescription('application_name'),
        filter: true,
        sortable: true,
        width: 900
      },
      {
        field: 'status',
        headerName: this.i18nProvider.getDescription('status'),
        filter: true,
        sortable: true,
        width: 300,
        enableRowGroup: true,
        cellClass: (params) => {
          return params.data?.active ? 'active' : 'inactive';
        }
      }];
  }
}
